import { TrackingEvent } from '../types';

export default class ViewaAnalytics {
    _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    async sendAnalytics(
        trackingCode: string,
        event: TrackingEvent
    ): Promise<void> {
        await fetch(`${this._baseUrl}?cid=${trackingCode}&evt=${event}`);
    }
}
